import React from 'react'

const MobileCourse = ({ handleClick, open }) => {
  return (
    <div className="proj_details mob_course">
      <div className="course_project mob_course_project">
        <img src={require("../img/mob_course.png")} alt="" />
        <div className="course_top">
          <p className="para">What will we be doing in each project?</p>
          <p className="para para_1 mt-5">Project X1</p>
          <h4>Learn how to:</h4>
          <ul className='pl-5'>
            <li>Build a personal portfolio site using PERN stack</li>
            <li>Leverage existing UI libraries</li>
            <li>Create reusable components that can be used for other projects</li>
          </ul>
          <p className="para para_1 mt-5">Project X2</p>
          <h4>Learn how to:</h4>
          <ul className='proj2 pl-5'>
            <li>Build a self-proposed greenfield web application</li> <br /> <p>OR</p>
            <p>enhance Project X1 and make it your own</p>
          </ul>
        </div>
      </div>
      <div className="projec_week">
        <p className="para">Weekly agenda:</p>

        <div class="accordion_div">
          <ul class="accordion_ul">
            <li className="accordion" onClick={() => handleClick(0)}>Week 1: Introduction 
              {open.includes(0) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
              : <img src={require("../img/arrow-down.png")} alt="" ></img>
              }
            </li>
            <div class="panel" style={open.includes(0) ? { display: "block" } : { display: "none" }}>
              <div class="accordion_info">
                <ul>
                  <li>Introduction to Web Development</li>
                  <li>What is the Internet? How does it work?</li>
                  <li>Introduction to HTML and CSS. </li>
                  <li>ow do browsers display information to the end user?</li>
                  <li>Introduction to Javascript</li>
                  <li> How does javascript fit into HTML and CSS to create interactive web applications?</li>
                </ul>
                <p>  </p>
              </div>
            </div>

            <li className="accordion" onClick={() => handleClick(1)}>Week 2: Frontend Framework 
              {open.includes(1) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
              : <img src={require("../img/arrow-down.png")} alt="" ></img>
              }
            </li>
            <div class="panel" style={open.includes(1) ? { display: "block" } : { display: "none" }}>
              <div className="accordion_info">
                <ul className='acc_desc'>
                  <li>Introduction to React (A framework built on Javascript)</li>
                  <li>Why use React instead of HTML/CSS and JS</li>
                  <li>Project 1 FE kickoff!</li>
                </ul>
              </div>
            </div>

            <li className="accordion" onClick={() => handleClick(2)}>Week 3: Clean Code 
              {open.includes(2) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
              : <img src={require("../img/arrow-down.png")} alt="" ></img>
              }
            </li>
            <div class="panel" style={open.includes(2) ? { display: "block" } : { display: "none" }}>
              <div className="accordion_info">
                <ul className='acc_desc'>
                  <li>Continue development on Project 1.</li>
                  <li>Reusable Components.</li>
                  <li>Backend</li>
                </ul>
              </div>
            </div>
            <li className="accordion" onClick={() => handleClick(3)}>Week 4: Backend Frameworks 
              {open.includes(3) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
              : <img src={require("../img/arrow-down.png")} alt="" ></img>
              }
            </li>
            <div class="panel" style={open.includes(3) ? { display: "block" } : { display: "none" }}>
              <div className="accordion_info">
                <ul className='acc_desc'>
                  <li>Introduction to NodeJS and Express</li>
                  <li>Basics of NodeJS and how to create a backend server with CRUD requests</li>
                  <li>Introduction to Databases</li>
                  <li>Project 1 BE kickoff!</li>
                </ul>
              </div>
            </div>
            <li className="accordion" onClick={() => handleClick(4)}>Week 5: SQL  
              {open.includes(4) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
              : <img src={require("../img/arrow-down.png")} alt="" ></img>
              }
            </li>
            <div class="panel" style={open.includes(4) ? { display: "block" } : { display: "none" }}>
              <div className="accordion_info">
                <ul className='acc_desc'>
                  <li>Continue development on Project 1.</li>
                  <li>Relational vs Non-relational databases.</li>
                  <li>Introduction to Postgres.</li>
                  <li>SQL Queries.</li>
                  <li>Formation of groups and introduction to Group project.</li>
                </ul>
              </div>
            </div>
            <li className="accordion" onClick={() => handleClick(5)}>Week 6: Fullstack Development  
              {open.includes(5) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
              : <img src={require("../img/arrow-down.png")} alt="" ></img>
              }
            </li>
            <div class="panel" style={open.includes(5) ? { display: "block" } : { display: "none" }}>
              <div className="accordion_info">
                <ul className='acc_desc'>
                  <li>Integrating the Backend into Frontend to create a Fullstack web application.</li>
                  <li>Introduction to libraries such as axios to help make CRUD requests from FE</li>
                  <li>Project 2 development</li>
                </ul>
              </div>
            </div>
            <li className="accordion" onClick={() => handleClick(6)}>Week 7: Deployment   
              {open.includes(6) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
              : <img src={require("../img/arrow-down.png")} alt="" ></img>
              }
            </li>
            <div class="panel" style={open.includes(6) ? { display: "block" } : { display: "none" }}>
              <div className="accordion_info">
                <ul className='acc_desc'>
                  <li>How to host your websites.</li>
                  <li>Project 2 development</li>
                </ul>
              </div>
            </div>
            <li className="accordion" onClick={() => handleClick(7)}>Week 8: Peer Learning 
              {open.includes(7) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
              : <img src={require("../img/arrow-down.png")} alt="" ></img>
              }
            </li>
            <div class="panel" style={open.includes(7) ? { display: "block" } : { display: "none" }}>
              <div className="accordion_info">
                <ul className='acc_desc'>
                  <li>Project Presentation</li>
                  <li>Consolidate understanding and concepts.</li>
                  <li>Close off</li>
                </ul>
              </div>
            </div>
          </ul>
        </div>

      </div>
    </div>
  )
}

export default MobileCourse