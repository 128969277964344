import { createContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import Popup from './component/Popup';
import ScrollToTop from './component/ScrollToTop';
import Courses from './pages/Courses';
import Home from './pages/Home';
import Instructor from './pages/Instructor';
import SignUp from './pages/SignUp';


const store = createContext()
const { Provider } = store
export { store }
function App() {
  return (
    <div className="App">
      <Provider>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/" element={<Home />} />
            <Route path="/popup" element={<Popup />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/instructor" element={<Instructor />} />
          </Routes>
        </BrowserRouter>
      </Provider>

    </div>
  );
}

export default App;

