import React, { useState } from 'react'

const InstructorDetail = () => {
  const [state, setState] = useState(true)
  return (
    <div className="instructor_details mobile_instructor">
      <p className='para'>
        {
          state ?
            <>
              Through our own experiences, we identified a gap between what tech employers are looking for and the current syllabus that is being taught in schools. Schools tend to focus more on theoretical things whereas companies are looking for students' ability to apply programming skills.  Many employers look for past projects that display your ability to apply current technologies to actual products.
            </>
            :
            <>
              Through our own experiences, we identified a gap between what tech employers are looking for and the current syllabus that is being taught in schools. Schools tend to focus more on theoretical things whereas companies are looking for students' ability to apply programming skills.  Many employers look for past projects that display your ability to apply current technologies to actual products.
              <br />When we were students, programming seemed daunting, with there being limited help online, with school being of little help.
              <br />Hence we created this course that we would have greatly appreciated in our time. Not only do we hope to equip students with relevant skills, we hope to build a community where students can learn from one another and continue to improve and develop even after the course.
            </>
        }
      </p>
      <div className='mob_signup_popup'>
        <button style={{ fontSize: "1.6em" }} onClick={() => setState(!state)}>{state ? "more" : "less"}</button>
      </div>
    </div>
  )
}

export default InstructorDetail