import React, { useState } from 'react';
import Footer from '../component/Footer';
import InstructorDetail from '../component/InstructorDetail';
import Navbar from '../component/Navbar';
import Popup from '../component/Popup';
import WillyPopup from '../component/WillyPopup';
import './Instructor.css';

const Instructor = () => {
  const [firstPopup, setFirst] = useState(false)
  const [secondPopup, setSecond] = useState(false)
  return (
    <div className="section">{
      (firstPopup || secondPopup) ?
        <>
          {firstPopup && <Popup setFirst={setFirst} />}
          {secondPopup && <WillyPopup setSecond={setSecond} />}
        </>
        :
        <>

          <Navbar />
          <div className='main_section'>
            <div className=" signup_content instructor_content" style={{height:"auto"}}>
              <h1 className="header">Instructors</h1>
              <InstructorDetail />
              <div className="instructor_details web_instructor">
                <p className='para'>Through our own experiences, we identified a gap between what tech employers are looking for and the current syllabus that is being taught in schools. Schools tend to focus more on theoretical things whereas companies are looking for students' ability to apply programming skills.  Many employers look for past projects that display your ability to apply current technologies to actual products.</p>
                <p className='para'>When we were students, programming seemed daunting, with there being limited help online, with school being of little help. </p>

                <p className='para'>Hence we created this course that we would have greatly appreciated in our time. Not only do we hope to equip students with relevant skills, we hope to build a community where students can learn from one another and continue to improve and develop even after the course.</p>
              </div>

              <div className="instructor_meet">
                <h1 className='header'>Meet the instructors</h1>
                <div className="d-flex align-items-start justify-content-between instructor_div">

                  <div onClick={() => {
                    setFirst(true)
                  }} className="ryan d-flex flex-column justify-content-center align-items-center">

                    <div className="ryan_img">
                      <img src={require("../img/popup.png")} alt="" className='non-hover-1' />
                      <img src={require("../img/popup-white.png")} alt="" className='hover-1-image' />
                      <h5>Ryan</h5>
                      <p>Ryan is a Computer science graduate from the National University of Singapore working as a Backend Software Engineer @ YouTrip</p>
                      <p className='mt-5 hover-1 w-100'>Other experiences:</p>
                      <ul className='flex-column justify-content-start pl-5 hover-1'>
                        <li>Fullstack Software Engineer @ Pints</li>
                        <li>Fullstack Software Engineer @ Vivita (Intern)</li>
                        <li>Fullstack Software Engineer @ StaffAny (Intern)</li>
                        <li>Software QA Tester @ Akronym (Morning)</li>
                      </ul>
                    </div>
                  </div>
                  <div onClick={() => {
                    setSecond(true)
                  }} className="willy d-flex flex-column justify-content-center align-items-center">
                    <div className="ryan_img">
                      <img src={require("../img/popup.png")} alt="" className='non-hover-2' />
                      <img src={require("../img/popup-white.png")} alt="" className='hover-2-image' />
                      <h5>Willy</h5>
                      <p>Willy is a Computer Science graduate at the National University of Singapore currently working as a Backend Software Engineer @ Binance</p>
                      <p className='mt-5 hover-2 w-100'>Other experiences:</p>
                      <ul className='flex-column justify-content-start pl-5 hover-2'>
                        <li>Graduated with a CAP of 4.67/5.0 and was placed on the Dean's List in AY2019/2020. He was also chosen to participate in a 1-year internship at Silicon Valley.</li>
                        <li>Backend Software Engineer @ LeadIQ</li>
                        <li>Frontend Software Engineer @ Defence Security Organisation</li>
                        <li>Web developer @ Timestory</li>
                        <li>Quality Assurance Intern @ V-Key</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />

          </div>
        </>
    }

    </div>
  )
}

export default Instructor