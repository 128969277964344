import React from 'react';
import './Popup.css'

const WillyPopup = ({ setSecond }) => {
  return (
    <div className='card-popup-willy'>
        <div className="willy willy-pop d-flex flex-column justify-content-center align-items-center bg-white">
          <div className="popup_cross w-100 d-flex justify-content-end" >
            <i class="fa-solid fa-xmark" onClick={() => setSecond(false)}></i>
          </div>
          <div className="ryan_img">
            <img src={require("../img/popup-white.png")} alt="" />
            <h5>Willy</h5>
            <p>Willy is a Computer Science graduate at the National University of Singapore currently working as a Backend Software Engineer @ Binance</p>
            <p className='mt-3'>Other experiences:</p>
            <ul className='pl-5'>
              <li>Graduated with a CAP of 4.67/5.0 and was placed on the Dean's List in AY2019/2020. He was also chosen to participate in a 1-year internship at Silicon Valley.</li>
              <li>Backend Software Engineer @ LeadIQ</li>
              <li>Frontend Software Engineer @ Defence Security Organisation</li>
              <li>Web developer @ Timestory</li>
              <li>Quality Assurance Intern @ V-Key</li>
            </ul>
          </div>
        </div>
      </div>

  )
}

export default WillyPopup