import React from 'react';
import { useNavigate } from 'react-router-dom';
// import './MobileHome.css';

const MobileHome = () => {
    const navigate = useNavigate()
    return (

        <div className="home_head mobile_home d-flex flex-column">
            <div className="home_top">
                <h1 className="header">Struggling with web <br /> development alone?<br />
                    We know, we’ve been <br /> there too.   </h1>
                <p className='para'>We know what to teach, how best to teach it.</p>
                <img src={require("../img/mob_home_img.png")} alt="" />
                <div className="d-flex align-items-center justify-content-end w-100">
                 <button className="view_course"  onClick={() => {
            navigate("/courses")
          }}>View Courses</button>
                 </div>
            </div>
        </div>
    )
}

export default MobileHome