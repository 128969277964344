import React, { useEffect, useState } from 'react';
import CourseBtn from '../component/CourseBtn';
import Footer from '../component/Footer';
import Navbar from '../component/Navbar';
import Popup2 from '../component/Popup2';
import SignupPopup from '../component/SignupPopup';

import './SignUp.css';

const SignUp = () => {
  const [showSignupPopup, setSignupPopup] = useState(false)
  const [showPopup, setPopup] = useState(false)
  const [data,setData] = useState({
    name:"",
    email:""
  })

  useEffect(()=>{
    if(!showPopup){
      setData({
        name:"",
        email:""
      })
    }
  },[showPopup])
  return (
    <div className="section">
        <Navbar />
      <div className='main_section mt-5'>

        <div className="signup_content">
          <h1 className='header'>Sign up</h1>
          <div className="d-flex justify-content-between">
            <div className="signup_left web_signup_popup">
              <h6 className='para'>Course Details:</h6>
              <div className="my-5 signup-para">
                <p className='mb-0'><span className='para' style={{marginRight:"34px"}}>Pricing: </span> $600 for 8 sessions</p>
                <p className='mb-0'><span className='para signup-para-span'>Duration:</span> 1 x 3 hour session a week (8 weeks in total)</p>
                <p className='mb-0'><span className='para signup-para-span'>Location:</span> In person (address to be determined soon)</p>
                <p className='mb-0'><span className='para' style={{marginRight:"12px"}}>Class size: </span> 5 ~ 15 people.</p>
              </div>
              <h6 className='para'>*flexible payment schemes available (you can pay in instalments or in full), contact our team for more info</h6>

            </div>
            <div className="signup_right d-flex flex-column">
              <h6 className='para'>As we are just starting out, we will contact you after signing up via email, payment will be made when you come down for the first lesson, thank you! </h6>
              <CourseBtn setSignupPopup={setSignupPopup}/>
              <input type="text" onChange={e=>setData({...data,name:e.target.value})} value={data.name} placeholder='Your name' className='input_1' />
              <input type="text" onChange={e=>setData({...data,email:e.target.value})} value={data.email} placeholder='Your email' />
              <button className='para join_codex' onClick={() => setPopup(true)}>Join Codex Family</button>
            </div>
          </div>
        </div>



        <Footer />
      </div>
      {showSignupPopup && <SignupPopup setSignupPopup={setSignupPopup}/>}
      {showPopup && <Popup2 setPopup={setPopup} />}

    </div>
  )
}

export default SignUp