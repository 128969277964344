import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../component/Footer';
import MobileGoals from '../component/MobileGoals';
import MobileHome from '../component/MobileHome';
import Navbar from '../component/Navbar';
import './Home.css';

const Home = () => {
  const navigate = useNavigate()
  return (
    <div className="section">
        <Navbar />
      <div className='main_section'>
        <div className="home_content course_content signup_content">
          <MobileHome />
          <div className="home_head web_home">
            <img src={require("../img/home_img.png")} alt=""  />
            <div className="home_top">
              <h1 className="header">Struggling with web <br /> development alone?<br />
                We know, we’ve been <br /> there too.   </h1>
              <p className='para'>We know what to teach, how best to teach it.</p>
              <button className="view_course" onClick={() => {
                            navigate("/courses")
                        }}>View Courses</button>
            </div>
          </div>
          <div className="course_detail home_course_div">
            <h1 className="header">Why take our <span>course?</span></h1>
            <div className="course_detail_div d-flex justify-content-around text-center">

              <div className="div_2">
                <img src={require("../img/key.png")} alt="" />
                <p className="para pt-3">Gain <span>access to internship</span> opportunities and careers</p>
              </div>
              <div className="div_2">
                <img src={require("../img/insight.png")} alt="" />
                <p className="para pt-3">Gain <span>insights</span> into the development world</p>
              </div>
              <div className="div_2">
                <img src={require("../img/self-improvement.png")} alt="" />
                <p className="para pt-3"><span>Build on your own skill sets</span> as a developer that sets you apart from the rest </p>
              </div>
              <div className="div_2">
                <img src={require("../img/group.png")} alt="" />
                <p className="para pt-3">Tap onto <span>reliable sources</span> of help that caters to your needs</p>
              </div>
            </div>
          </div>
          <div>
            <h1 className="header" style={{textAlign: "center", textDecoration: "underline"}}>Our Mission & Vision</h1>
            <div className="mission">
              <h3>Our <span>Mission</span></h3>
              <p className='para mt-5'>We want to empower coders of tomorrow through helping them build web development fundamentals.</p>
            </div>
            <div className="mission">
              <h3>Our <span>Vision</span></h3>
              <p className='para mt-5'>We want to empower coders of tomorrow through helping them build web development fundamentals.</p>
            </div>

          </div>
          <MobileGoals />
          <div className="goals_us web_goals_us">
            <div className="goals goals-2">
              <h1 className="header" style={{textAlign: "center", textDecoration:"underline"}}>Goals</h1>
              <div className="goal_1 d-flex justify-content-between">
                <div className='goal-content'>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <img src={require("../img/skills.png")} alt="" style={{width: "50px", height: "50px", marginBottom: "14px", marginRight: "14px"}}></img>
                    <p style={{marginBottom: 0, fontFamily: "avenir-bold"}}>Upskill</p>
                  </div>
                  <p className="para">Equip individuals with essential industry-related knowledge & skills that are difficult to attain in schools</p>
                </div>
                <div className='goal-content'>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <img src={require("../img/advice.png")} alt="" style={{width: "50px", height: "50px", marginBottom: "14px", marginRight: "14px"}}></img>
                    <p style={{marginBottom: 0, fontFamily: "avenir-bold"}}>Quality Advice</p>
                  </div>
                  <p className="para">Give individuals a community of coders that they can learn and seek advice from</p>
                </div>
              </div>


            </div>
            <div className="goals goals-1">
              <h1 className="header" style={{textAlign: "center", textDecoration:"underline"}}>Why Us?</h1>
              <div className="goal_1 d-flex justify-content-between">
                <div className='goal-content'>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <img src={require("../img/effort.png")} alt="" style={{width: "50px", height: "50px", marginBottom: "14px", marginRight: "14px"}}></img>
                    <p style={{marginBottom: 0, fontFamily: "avenir-bold"}}>Overcome Struggles</p>
                  </div>
                  <p className="para">In-depth understanding of the struggles embarking on coding tracks in Singapore</p>
                </div>
                <div className='goal-content'>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <img src={require("../img/promotion.png")} alt="" style={{width: "50px", height: "50px", marginBottom: "14px", marginRight: "14px"}}></img>
                    <p style={{marginBottom: 0, fontFamily: "avenir-bold"}}>Thrive</p>
                  </div>
                  <p className="para">Experience and knowledge of what is needed to flourish and thrive in the industry</p>
                </div>
              </div>
            </div>
            <div className="view_course_btn">
              <p style={{fontFamily: "avenir-bold", fontSize: "36px"}}>Want to know more? Check out our course by clicking the button below!</p>
              <button className="view_course" onClick={() => {
                            navigate("/courses")
                        }}>View Courses</button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Home