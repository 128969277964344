import React from 'react';
import './Popup2.css';

const Popup2 = ({setPopup}) => {
    return (
        <div className='popup2_bg d-flex justify-content-center'>
            <div className="popup2_content">
                <p className='para'>Thank you for joining Codex Family, we will be contacting you via email soon.</p>
                <button onClick={()=>setPopup(false)}>Got it!</button>
            </div>
        </div>
    )
}

export default Popup2