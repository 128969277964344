import React, { useState } from 'react'
import SignupPopup from './SignupPopup'

const CourseBtn = ({setSignupPopup}) => {
  return (
    <div className='mob_signup_popup'>
      <button onClick={()=>setSignupPopup(true)}>View course details</button>
    </div>
  )
}

export default CourseBtn