import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <div id="header" className='sticky'>
      <nav className='pt-3 pb-4'>
        <input type="checkbox" id="check" />
        <label for="check" className="checkbtn barbtn">
          <i class="fas fa-bars fa-lg"></i>
        </label>
        <label for="check" className="checkbtn crossbtn">
          <i class="fa-solid fa-xmark fa-lg"></i>
        </label>
        <a href='#top'>
        <label class="logo" style={{cursor:"pointer"}} onClick={() => {
          navigate("/")
        }}>
          <img src={require("../img/logo.png")} alt=""  /> &nbsp;
          Codex Labs</label>
        </a>
        <ul className=''>
          <li><a className={`${location.pathname == "/courses" ? "active" : ""} nav_1`} href="#." onClick={() => {
            navigate("/courses")
          }}>View courses</a></li>
          <li><a href="#." className={`${location.pathname == "/instructor" ? "active" : ""} nav_1 nav_2`} onClick={() => {
            navigate("/instructor")
          }}>Instructors</a></li>
          <li><button onClick={() => {
            navigate("/signup")
          }}>Sign Up</button></li>
        </ul>
      </nav>

    </div>
  )
}

export default Navbar