import React from 'react';
import './Popup.css'

const Popup = ({ setFirst }) => {
  return (
    <div className='card-popup-ryan'>
      <div className="ryan ryan-pop d-flex flex-column justify-content-center align-items-center bg-white">
        <div className="popup_cross w-100 d-flex justify-content-end">
          <i class="fa-solid fa-xmark" onClick={() => setFirst(false)}></i>
        </div>
        <div className="ryan_img">
          <img src={require("../img/popup-white.png")} alt="" />
          <h5>Ryan</h5>
          <p>Ryan is a Computer science graduate from the National University of Singapore working as a Backend Software Engineer @ YouTrip</p>
          <p className='mt-5'>Other experiences:</p>
          <ul className='pl-5'>
            <li>Fullstack Software Engineer @ Pints</li>
            <li>Fullstack Software Engineer @ Vivita (Intern)</li>
            <li>Fullstack Software Engineer @ StaffAny (Intern)</li>
            <li>Software QA Tester @ Akronym (Morning)</li>
          </ul>
        </div>
      </div>
      </div>
  )
}

export default Popup