import React from 'react';
import './Footer.css';

const Footer = () => {
  
  return (
    <div className='footer_section w-100'>
      <div className='footer d-flex w-50 justify-content-between'>
        <div className="footer_follow">
          <p className='footer_para'>Follow</p>
          <p className='f-para'><a href="https://www.linkedin.com">Linkedin</a></p>
          <p className='f-para'><a href="http://www.instagram.com">Instagram</a></p>
        </div>
        <div className="footer_contact">
          <p className='footer_para'>Contact us </p>
          <p className='f-para'>hello@thexfactor.com</p>
          <p className='f-para'>+65 1234 5678</p>
        </div>
        <div className="footer_legal">
          <p className='footer_para'>Legal</p>
          <p className='f-para'>Terms and service</p>
          <p className='f-para'>Privacy policy </p>
        </div>

      </div>
      <hr />
      <p className='all_rights'>© 2022 All Rights Reserved.</p>
    </div>
  )
}

export default Footer