import React from 'react'
import { useNavigate } from 'react-router-dom'

const MobileGoals = () => {
  const navigate = useNavigate()
  return (
    <div className="goals_us mob_goals_us">
            <div className="goals goals-2">
              <h1 className="header" style={{ textDecoration: "underline" }}>Goals</h1>
              <div className="goal_1 d-flex justify-content-between">
              <div className='goal-content'>
                <div style={{display: "flex", alignItems: "center"}}>
                    <img src={require("../img/skills.png")} alt="" style={{width: "50px", height: "50px", marginBottom: "14px", marginRight: "14px"}}></img>
                    <p style={{marginBottom: 0, fontFamily: "avenir-bold"}}>Upskill</p>
                  </div>
                  <p className="para">Equip individuals with essential industry-related knowledge & skills that are difficult to attain in schools</p>
                </div>
                <div className='goal-content'>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <img src={require("../img/advice.png")} alt="" style={{width: "50px", height: "50px", marginBottom: "14px", marginRight: "14px"}}></img>
                    <p style={{marginBottom: 0, fontFamily: "avenir-bold"}}>Quality Advice</p>
                  </div>
                  <p className="para">Give individuals a community of coders that they can learn and seek advice from</p>
                </div>
              </div>


            </div>
            <div className="goals goals-1">
              <h1 className="header" style={{ textDecoration: "underline" }}>Why Us?</h1>
              <div className="goal_1 d-flex justify-content-between">
                <div className='goal-content'>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <img src={require("../img/effort.png")} alt="" style={{width: "50px", height: "50px", marginBottom: "14px", marginRight: "14px"}}></img>
                    <p style={{marginBottom: 0, fontFamily: "avenir-bold"}}>Overcome Struggles</p>
                  </div>
                  <p className="para">In-depth understanding of the struggles embarking on coding tracks in Singapore</p>
                </div>
                <div className='goal-content'>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <img src={require("../img/promotion.png")} alt="" style={{width: "50px", height: "50px", marginBottom: "14px", marginRight: "14px"}}></img>
                    <p style={{marginBottom: 0, fontFamily: "avenir-bold"}}>Thrive</p>
                  </div>
                  <p className="para">Experience and knowledge of what is needed to flourish and thrive in the industry</p>
                </div>
              </div>
            </div>
            <div className="view_course_btn mt-5">
              <button className="view_course" onClick={() => {
            navigate("/courses")
          }}>View Courses</button>
            </div>
          </div>
  )
}

export default MobileGoals