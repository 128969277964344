import React from 'react';
import './SignupPopup.css';

const SignupPopup = ({setSignupPopup}) => {
    return (
        <div className="mobile_signup_popup d-flex justify-content-center" onClick={()=>setSignupPopup(false)}>
            <div className="signup_left mobile_signup_popup_1">
                <div className='d-flex justify-content-center align-items-center'>
                <h6 className='para'>Course Details</h6>
                </div>
                <div className="my-4 signup-para">
                    <p className='mb-0'><span className='para' style={{marginRight:"26px"}}>Pricing: </span> $600 for 8 sessions</p>
                    <p className='mb-0'><span className='para' style={{marginRight:"14px"}}>Duration: </span> 1 x 3 hour session a week (8 weeks in total)</p>
                    <p className='mb-0'><span className='para' style={{marginRight:"19px"}}>Location: </span> In person (address to be determined soon)</p>
                    <p className='mb-0'><span className='para' style={{marginRight:"12px"}}>Class size: </span> 5 ~ 15 people.</p>
                </div>
                <h5 className='para'>*flexible payment schemes available (you can pay in instalments or in full), contact our team for more info</h5>

            </div>
        </div>
    )
}

export default SignupPopup