import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../component/Footer';
import MobileCourse from '../component/MobileCourse';
import Navbar from '../component/Navbar';
import './Courses.css';

const Courses = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState([0])
  const [slideIndex, setSlideIndex] = useState(1)
  const [later, setLater] = useState(false)

  const handleClick = (n) => {
    setOpen(o => o.includes(n) ? o.filter(v => v !== n) : ([...o, n]))
  }

  useEffect(() => {
    showSlides(slideIndex);
  }, [])

  function plusSlides(n) {
    let currentSlideIndex = slideIndex + n
    setSlideIndex(currentSlideIndex)
    showSlides(currentSlideIndex);
  }

  function currentSlide(n) {
    setSlideIndex(n)
    showSlides(n);
  }

  function showSlides(n) {
    let currentSlideIndex = n
    let slides = document.getElementsByClassName("mySlides");
    let dots = document.getElementsByClassName("dot");
    if (n > slides.length) {
      currentSlideIndex = 1
    }
    if (n < 1) {
      currentSlideIndex = slides.length
    }
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (let i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active-dot", "");
    }
    slides[currentSlideIndex - 1].style.display = "block";
    dots[currentSlideIndex - 1].className += " active-dot";
    setSlideIndex(currentSlideIndex)
    console.log('currentSlideIndex', currentSlideIndex)
  }

  return (
    <div className="section">
        <Navbar />
      <div className='main_section'>
        <div className="course_content signup_content">
          <h1 className="header">Web Dev 101</h1>
          <div className="course_guide">
            <p className='para'>Through this course, you will be programming your own portfolio by working on 2 specially designed projects under the guidance of experienced mentors</p>
            <p className='mt-5'>You will:</p>
            <ol className='pl-5'>
              <li>Learn the basics of programming and full-stack web development</li>
              <li>Learn in-trend technology useful for tech internships/jobs</li>
              <li>Acquire skills to build interactive web applications</li>
            </ol>
          </div>
          <div className="course_detail">
            <div className='w-100'><h1 className="header">Course Details</h1></div>
            <div className="course_detail_div course_detail_div1 d-flex text-center" style={{ boxShadow: "8px 8px 6px 8px #888888", padding: "4rem 2rem 2rem 2rem"}}>

              <div className="div_1">
                <img src={require("../img/salary.png")} alt=""  style={{width: "50px"}}/>
                <p className="para pt-3">$600 for 8 sessions</p>
              </div>
              <div className="div_1">
                <img src={require("../img/calendar.png")} alt="" style={{width: "50px"}}/>
                <p className="para pt-3">1 x 3 hour session a week<br></br>(8 weeks in total)</p>
              </div>
              <div className="div_1">
                <img src={require("../img/map.png")} alt=""  style={{width: "50px"}}/>
                <p className="para pt-3">To be confirmed soon</p>
              </div>
              <div className="div_1">
                <img src={require("../img/partnership.png")} alt=""  style={{width: "50px"}}/>
                <p className="para pt-3">5-15 people per className</p>
              </div>
            </div>
          </div>
          <MobileCourse handleClick={handleClick} open={open}/>

          <div className="proj_details web_course">
            <div className="course_project">
              <img src={require("../img/course_img.png")} alt="" />
              <div className="course_top">
                <p className="para" style={{fontSize: "24px"}}>What will we be doing in each project?</p>
                <p className="para para_1 mt-5">Project X1</p>
                <h4>Learn how to:</h4>
                <ul className='pl-5'>
                  <li>Build a personal portfolio site using PERN stack</li>
                  <li>Leverage existing UI libraries</li>
                  <li>Create reusable components that can be used for other projects</li>
                </ul>
                <p className="para para_1 mt-5">Project X2</p>
                <h4>Learn how to:</h4>
                <ul className='proj2 pl-5'>
                  <li>Build a self-proposed greenfield web application</li> <br /> <p style={{color:"#5A5A5A"}}>OR</p>
                  <p style={{color:"#5A5A5A"}}>Enhance Project X1 and make it your own</p>
                </ul>
              </div>
            </div>
            <div className="projec_week">
              <p className="para">Weekly agenda:</p>

              <div className="accordion_div">
                <ul className="accordion_ul">
                  <li className="accordion" onClick={() => handleClick(0)}>Week 1: Introduction 
                    {open.includes(0) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
                    : <img src={require("../img/arrow-down.png")} alt="" ></img>
                    }
                  </li>
                  <div className="panel" style={open.includes(0) ? { display: "block" } : { display: "none" }}>
                    <div className="accordion_info" >
                      <ul className='acc_desc'>
                        <li>Introduction to Web Development</li>
                        <li>What is the Internet? How does it work?</li>
                        <li>Introduction to HTML and CSS. </li>
                        <li>ow do browsers display information to the end user?</li>
                        <li>Introduction to Javascript</li>
                        <li> How does javascript fit into HTML and CSS to create interactive web applications?</li>
                      </ul>
                    </div>
                  </div>

                  <li className="accordion" onClick={() => handleClick(1)}>Week 2: Frontend Framework 
                    {open.includes(1) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
                    : <img src={require("../img/arrow-down.png")} alt="" ></img>
                    }
                  </li>
                  <div className="panel" style={open.includes(1) ? { display: "block" } : { display: "none" }}>
                    <div className="accordion_info">
                      <ul className='acc_desc'>
                        <li>Introduction to React (A framework built on Javascript)</li>
                        <li>Why use React instead of HTML/CSS and JS</li>
                        <li>Project 1 FE kickoff!</li>
                      </ul>
                    </div>
                  </div>

                  <li className="accordion" onClick={() => handleClick(2)}>Week 3: Clean Code 
                    {open.includes(2) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
                    : <img src={require("../img/arrow-down.png")} alt="" ></img>
                    }
                  </li>
                  <div className="panel" style={open.includes(2) ? { display: "block" } : { display: "none" }}>
                    <div className="accordion_info">
                      <ul className='acc_desc'>
                        <li>Continue development on Project 1.</li>
                        <li>Reusable Components.</li>
                        <li>Backend</li>
                      </ul>
                    </div>
                  </div>
                  <li className="accordion" onClick={() => handleClick(3)}>Week 4: Backend Frameworks 
                    {open.includes(3) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
                    : <img src={require("../img/arrow-down.png")} alt="" ></img>
                    }
                  </li>
                  <div className="panel" style={open.includes(3) ? { display: "block" } : { display: "none" }}>
                    <div className="accordion_info">
                      <ul className='acc_desc'>
                        <li>Introduction to NodeJS and Express</li>
                        <li>Basics of NodeJS and how to create a backend server with CRUD requests</li>
                        <li>Introduction to Databases</li>
                        <li>Project 1 BE kickoff!</li>
                      </ul>
                    </div>
                  </div>
                  <li className="accordion" onClick={() => handleClick(4)}>Week 5: SQL  
                    {open.includes(4) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
                    : <img src={require("../img/arrow-down.png")} alt="" ></img>
                    }
                  </li>
                  <div className="panel" style={open.includes(4) ? { display: "block" } : { display: "none" }}>
                    <div className="accordion_info">
                      <ul className='acc_desc'>
                        <li>Continue development on Project 1.</li>
                        <li>Relational vs Non-relational databases.</li>
                        <li>Introduction to Postgres.</li>
                        <li>SQL Queries.</li>
                        <li>Formation of groups and introduction to Group project.</li>
                      </ul>
                    </div>
                  </div>
                  <li className="accordion" onClick={() => handleClick(5)}>Week 6: Fullstack Development  
                    {open.includes(5) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
                    : <img src={require("../img/arrow-down.png")} alt="" ></img>
                    }
                  </li>
                  <div className="panel" style={open.includes(5) ? { display: "block" } : { display: "none" }}>
                    <div className="accordion_info">
                      <ul className='acc_desc'>
                        <li>Integrating the Backend into Frontend to create a Fullstack web application.</li>
                        <li>Introduction to libraries such as axios to help make CRUD requests from FE</li>
                        <li>Project 2 development</li>

                      </ul>
                    </div>
                  </div>
                  <li className="accordion" onClick={() => handleClick(6)}>Week 7: Deployment   
                    {open.includes(6) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
                    : <img src={require("../img/arrow-down.png")} alt="" ></img>
                    }
                  </li>
                  <div className="panel" style={open.includes(6) ? { display: "block" } : { display: "none" }}>
                    <div className="accordion_info">
                      <ul className='acc_desc'>
                        <li>Learn how to host your websites.</li>
                        <li>Embark on Project 2 development work, let your ideas run wild.</li>
                      </ul>
                    </div>
                  </div>
                  <li className="accordion" onClick={() => handleClick(7)}>Week 8: Peer Learning 
                    {open.includes(7) ? <img src={require("../img/arrow-down.png")} alt="" style={{rotate: "180deg"}}></img>
                    : <img src={require("../img/arrow-down.png")} alt="" ></img>
                    }
                  </li>
                  <div className="panel" style={open.includes(7) ? { display: "block" } : { display: "none" }}>
                    <div className="accordion_info">
                      <ul className='acc_desc'>
                        <li>Project Presentation</li>
                        <li>Consolidate understanding and concepts.</li>
                        <li>Close off</li>
                      </ul>
                    </div>
                  </div>
                </ul >
              </div >
            </div >
          </div >

          <div className="project_schedule">
            <p className="para">Class Schedule</p>
            <div className="slideshow-container">

              {[1, 2].map(v => (<div className="mySlides fade-slider">
                <div className="calender" style={{ width: '100%' }}>
                  <div className='d-flex justify-content-center'>
                    <h4 className='cal_year'>Jan-Feb</h4>
                  </div>

                  <div className='d-flex justify-content-center calender_div'>
                    <div className="cal-left">
                      <h1>Wednesday sessions</h1>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                    </div>
                    <hr />
                    <hr className='mob-hr my-4' style={{ height: '2px', width: '88%' }} />
                    <div className="cal-right">
                      <h1>Wednesday sessions</h1>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                      <div className="cal_date">
                        <h6>Week {v}</h6>
                        <p>4/1/23</p>
                        <p>7-9 PM</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>))}

              <a className="prev" onClick={e => plusSlides(-1)}>❮</a>
              <a className="next" onClick={e => plusSlides(1)}>❯</a>

            </div>
            <br />

            <div style={{ textAlign: "center" }}>
              <span className="dot" onClick={() => currentSlide(1)}></span>
              <span className="dot" onClick={() => currentSlide(2)}></span>
            </div>


          </div>
          
          <div className='d-flex align-items-center justify-content-end w-100'>
            <button className='sign-up-btn signup_1' onClick={() => {
              navigate("/signup")
            }}>Sign up now</button>
          </div>

        </div >
        <Footer />
      </div >
      {!later&&<div className="course_popup">
        <p className="popup-content">
          Take a real step into the world of development by
          <i> actually</i> doing projects
        </p>
        <div className='d-flex align-items-center w-100'>
          <button className='sign-up-btn mr-5' onClick={() => {
            navigate("/signup")
          }}>Sign up now</button>
          <button onClick={()=>setLater(true)} style={{color:"#FFF"}}>Maybe later</button>
        </div>
      </div>}
    </div >
  )
}

export default Courses